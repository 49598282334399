import React, {useRef, useEffect} from 'react';
import { RiJavascriptLine } from "react-icons/ri";
import { PiFileHtml, PiFileCss, PiFileCSharpFill } from "react-icons/pi";
import { AiOutlinePython } from "react-icons/ai";
import { DiNodejs, DiMongodb } from "react-icons/di";
import { FaAws, FaGitSquare, FaJava } from "react-icons/fa";
import { SiOracle, SiSpringboot, SiPostgresql } from "react-icons/si";
import { SlideRightAnimation, riseWithFade } from '../motion/animations.tsx';
import {motion, useInView} from "framer-motion"

function Skills() {
    const skills = [
        { icon: <RiJavascriptLine size={120} />, name: "JavaScript" },
        { icon: <PiFileHtml size={120} />, name: "HTML" },
        { icon: <PiFileCss size={120} />, name: "CSS" },
        { icon: <AiOutlinePython size={120} />, name: "Python" },
        { icon: <FaJava size={120} />, name: "Java" },
        { icon: <PiFileCSharpFill size={120} />, name: "C#" },
        { icon: <DiNodejs size={120} />, name: "Node.js" },
        { icon: <SiSpringboot size={120} />, name: "SpringBoot" },
        { icon: <FaAws size={120} />, name: "AWS Cloud Services" },
        { icon: < SiPostgresql size={120} />, name: "Postgres" },
        { icon: <DiMongodb size={120} />, name: "MongoDB" },
        { icon: <FaGitSquare size={120} />, name: "Git Version Control" },
    ];

    const ref = useRef(null);
    const isInView = useInView(ref);

    useEffect(() => {
        console.log("Is in view -> ", isInView);
    }, [isInView]);

    return (
        <div className='container min-h-screen flex flex-col justify-center items-center mb-20' id='skills'>
            <div className='flex justify-center items-center mb-10'>
                <motion.h1
                    ref={ref}
                    className="py-20 text-grey-700 text-3xl md:text-2xl lg:text-5xl"
                    initial="hidden"
                    animate={isInView ? "animate" : "initial"}
                    variants={riseWithFade}
                    transition={{ duration: 0.8 }}
                >
                    My <span className="font-bold">Skills</span>
                </motion.h1>
            </div>
            <div className='flex flex-wrap items-center justify-center'>
                {skills.map((skill, index) => (
                    <div key={index} className="m-2 flex items-center flex-col justify-center relative border-2 border-gray-800 bg-transparent w-48 h-48 font-medium uppercase text-gray-800 transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-y-0 before:bg-gray-800 before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-y-100">
                        {skill.icon}
                        {skill.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Skills;
