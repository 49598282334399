import React from 'react';
import { motion } from 'framer-motion';
import '../style/Navbar.css';
import { riseWithFade, staggerChildren, videoAnimation, wordAnimation } from "../motion/animations.tsx";

import { RevealLinks } from '../motion/Flyout.jsx';

function TopPage() {
  const text = "Coding visionary ready to bring your ideas to life.".split(" ");
  const name = "Hi, I am Jhio Soriano".split(" ");
  const jobDes = "A Software Developer".split("");

  return (
    <motion.div className="min-h-screen px-4 sm:px-6 md:px-8 lg:px-10" initial="initial" animate="animate" id='home'>
      <main className="grid grid-cols-1 md:grid-cols-2 lg:flex lg:justify-between gap-19 pt-10">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-8xl font-bold w-full md:w-full leading-tight md:leading-[90%] tracking-tight md:tracking-[-1px]">
          <AnimatedWords title="Hi I am Jhio Soriano" />
        </h1>
        <motion.div variants={riseWithFade} className="flex items-center text-sm sm:text-base md:text-lg lg:text-lg leading-6 lg:leading-8 w-[60vh]">
          "Bringing bold ideas to life with creativity and code. Let’s turn your vision into reality!"
        </motion.div>
      </main>
      <RevealLinks/>
      <motion.video loop autoPlay muted playsInline variants={videoAnimation} className="w-full mt-6 lg:mt-8">
        <source src="/assets/tumblr_91f22c08ef66bf0c588b735806a11129_fccca83e_540-ezgif.com-resize-video (1).mp4" type="video/mp4" />
      </motion.video>
      <footer className="flex justify-center text-xs sm:text-sm md:text-base text-zinc-400 py-4 md:py-6 lg:py-8">
        <p>Copyright 2024 Ghibli. All rights reserved.</p>
      </footer>
    </motion.div>
  );
}

export default TopPage;

type AnimatedWordsProps = {
  title: string;
};

export const AnimatedWords: React.FC<AnimatedWordsProps> = ({ title }) => {
  return (
    <motion.span variants={staggerChildren}>
      {title.split(" ").map((word, idx) => (
        <div key={idx} className="inline-block overflow-hidden">
          <motion.span
            className="inline-block overflow-hidden"
            variants={wordAnimation}
          >
            {word + "\u00A0"}
          </motion.span>
        </div>
      ))}
    </motion.span>
  );
};
