import React, { useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { motion, useScroll, useTransform, useSpring, useInView } from 'framer-motion';
import './App.css';
import NavBar from './components/NavBar';
import TopPage from './components/TopPage.tsx';
import Skills from './components/skills';
import Experience from './components/Experience';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import ContactMe from './components/Contact.jsx';
import { ExperienceContext } from './components/ExperienceContext';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
};

const Section = ({ children, id, sectionRef }) => {
  const { scrollY } = useScroll();
  const y = useSpring(useTransform(scrollY, [0, 1000], [0, -20]), {
    stiffness: 10,
    damping: 25,
  });

  return (
    <section id={id} ref={sectionRef} className="section">
      <motion.div style={{ y }}>
        {children}
      </motion.div>
    </section>
  );
};

function App() {
  const experienceRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  // Ensure useInView is applied correctly
  const experienceInView = useInView(experienceRef, { triggerOnce: false, threshold: 0.1 });
  const skillsInView = useInView(skillsRef, { triggerOnce: false, threshold: 0.5 });
  const projectsInView = useInView(projectsRef, { triggerOnce: false, threshold: 0.5 });
  const contactInView = useInView(contactRef, { triggerOnce: false, threshold: 0.5 });


  return (
    <ExperienceContext.Provider value={{ experienceInView, projectsInView, contactInView }}>
      <Router>
        <motion.div
          id='home'
          className="App"
          initial={{ backgroundColor: '#ffffff' }}
          animate={{ backgroundColor: experienceInView ? '#18181B' : '#ffffff' }}
          transition={{ duration: 1, ease: 'easeInOut' }}
      
        >
          <motion.div
            className="fixed top-0 left-0 w-full z-1"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
          >
            <NavBar />
          </motion.div>
          <motion.div
            className="spacing responsive"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
          >
            <TopPage />
          </motion.div>
          <Section id="skills" sectionRef={skillsRef}>
            <div className="spacing responsive">
              <Skills />
            </div>
          </Section>
          <motion.div
          className="App"
          initial={{ backgroundColor: '#ffffff' }}
          animate={{ backgroundColor: experienceInView ? '#18181B' : '#ffffff' }}
          transition={{ duration: 1, ease: 'easeInOut' }}
      
        >
          <Section id="experience" sectionRef={experienceRef}>
            <div className="spacing responsive">
              <Experience />
            </div>
          </Section>
          </motion.div>
          <motion.div
          className="App"
          initial={{ backgroundColor: '#ffffff' }}
          animate={{ backgroundColor: projectsInView? '#18181B' : '#ffffff' }}
          transition={{ duration: 1, ease: 'easeInOut' }}
      
        >
          <Section id="projects" sectionRef={projectsRef}>
          <div className="spacing responsive">
              <Projects />
            </div>
          </Section>
          </motion.div>

          <motion.div
          className="App"
          initial={{ backgroundColor: '#ffffff' }}
          animate={{ backgroundColor: contactInView ? '#18181B' : '#ffffff' }}
          transition={{ duration: 1, ease: 'easeInOut' }}
      
        >
          <Section id="contact" sectionRef={contactRef}>
            <ContactMe />
          </Section>
          </motion.div>
        </motion.div>
      </Router>
    </ExperienceContext.Provider>
  );
}

export default App;
