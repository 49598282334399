import React from 'react';
import { RevealBento } from '../motion/RevealBento';

const ContactMe = () => {

  return (
    <div className='' id='contact'>
      <RevealBento/>
    </div>
    );
};

export default ContactMe;