import React, {useState} from 'react'
import { Link } from 'react-scroll'
import {AiOutlineClose, AiOutlineMenu, AiOutlineMail} from 'react-icons/ai'
import { FaGithub } from 'react-icons/fa'
import { SlideTabs } from '../motion/NewNavbar'
import '../style/Navbar.css'
import { useExperience } from './ExperienceContext'
const NavBar = () => {
    const [nav, setNav] = useState(false)
    const { experienceInView, projectsInView, contactInView } = useExperience();
    const handleNav = () => {
        console.log("Toggling nav state", !nav);
        setNav(!nav)
    }
    const isGrayBackground = false;
    
    return (
        <div className='mt-2 ml-20'>
            <div onClick={handleNav} className='hidden-md makeTheMenuIntheRight'>
                <AiOutlineMenu size={25} />
            </div>
            <div className='container hidden-sm'>
                <div className='flex justify-between'>
                    <div className={`flex justify-center items-center ${experienceInView  || projectsInView || contactInView ? 'text-white' : 'text-black'}`}>
                        <Link className='no-underline' to="home" smooth={true} offset={-300} duration={100}>
                            <h3>IdontKnow.online</h3>
                        </Link>
                    </div>
                    <div>
                        <SlideTabs/>
                    </div>
                </div>
            </div>
            <div className={nav ? 'my-custom-overlay' : ''}>
                <div className={nav ? 'my-custom-component':'my-slide-in-component'}>
                    <div className='flex justify-end'>
                        <div onClick={handleNav} className='my-rounded-shadow-button'>
                            <AiOutlineClose/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar