import React from "react";
import SunsabLogo from "../Images/logo1.svg";
import musicalMoonLogo from "../Images/musicalmoon.png";
import { FiArrowRight } from "react-icons/fi";
 // Import other logos

const HoverDevCards = () => {
    const experiences = [
        {
            title: "Back End Developer at SunSab",
            description: "I developed scalable backend REST APIs using Java (Spring Boot) for a doctor form website, enabling patients to fill out and submit forms online while optimizing data flow and improving system performance. Additionally, I built a backend for an autograder system in Python (Flask) to handle SQL file submissions, process them for testing, and evaluate scores. I ensured high code quality and reliability by creating and executing unit tests, which reduced post-deployment bugs. In collaboration with the team, I successfully integrated front-end applications with backend services such as databases, APIs, and other critical services. I deployed applications on AWS using ECS, EC2, and S3, improving system scalability and uptime. Working within Scrum methodologies, I contributed to streamlined project delivery and fostered an engaging and collaborative work environment.",
            end: "May 2024 - Present",
            logo: SunsabLogo,
            href: "https://sunsab.com/"
        },
        {
            title: "MusicalMoon",
            description: "I designed and developed website layouts and user interfaces for a music platform using Next.js, significantly improving site navigation and overall user experience. Leveraging front-end frameworks such as Bootstrap, Tailwind, Framer Motion, and Mantine, I built responsive and visually appealing web pages that boosted user engagement. I also translated design mockups into functional web pages using HTML, CSS, and JavaScript, ensuring seamless implementation and consistency across different platforms.",
            end: "January 2024 - May 2024",
            logo: musicalMoonLogo,
            href: "https://www.riipen.com/"
        },
        // Add more job experiences as needed
    ];

    return (
        <div className="flex flex-col justify-center">
            {experiences.map((job, index) => (
                <Card
                    key={index}
                    title={job.title}
                    subtitle={job.description}
                    href={job.href}
                    end={job.end}
                    logo={job.logo}
                />
            ))}
        </div>
    );
};

const Card = ({ title, subtitle, href, end, logo }) => {
    return (
        <a
            href={href}
            className="no-underline w-full p-4 rounded border-[2px] border-gray-600 relative overflow-hidden group bg-gray-950 text-white transition-opacity duration-300 mb-10"
        >
            <div className="absolute inset-0 bg-gray-900 translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300" />
            <div className="flex justify-between">
                <div className="flex flex-row">
                    <img src={logo} className="w-20 z-10 mr-5 mb-2" alt="Company Logo"/>
                    <h3 className="font-medium text-sm md:text-lg lg:text-lg z-10">
                        {title}
                    </h3>
                </div>
                <div className="text-sm z-10 hidden sm:block">
                    {end}
                </div>
                
            </div>
            {/* Second 'end' element that will be visible on small screens */}
            <div className="lg:overflow-hidden relative text-sm z-10 sm:hidden mt-2 mb-2">
                {end}
            </div>
            <div className="text-gray-400 relative z-10">
                {subtitle}
            </div>
            
        </a>
    );
};

export default HoverDevCards;
