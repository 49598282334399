import React, { useRef, useState } from "react";
import { inView, motion } from "framer-motion";
import { Link } from 'react-scroll';
import { useExperience } from '../components/ExperienceContext'; // Import the custom hook

export const SlideTabs = () => {
  const { experienceInView, projectsInView, contactInView } = useExperience(); // Access the inView state
  const [position, setPosition] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  });

  return (
    <ul
      onMouseLeave={() => {
        setPosition((pv) => ({
          ...pv,
          opacity: 0,
        }));
      }}
      className={`relative mx-auto flex w-fit border-2 rounded-lg ${experienceInView  || projectsInView || contactInView ? 'border-white ' :'border-black bg-white'} p-1 z-100`}
    >
      <Link className='no-underline' to="skills" smooth={true} offset={0} duration={100} >
        <Tab setPosition={setPosition}  >
          Skills
        </Tab>
      </Link>
      <Link className='no-underline' to="experience" smooth={true} offset={0} duration={100}>
        <Tab setPosition={setPosition}>
          My Experience
        </Tab>
      </Link>

      <Link className='no-underline' to="project" smooth={true} offset={20} duration={100}>
        <Tab setPosition={setPosition}>
          Project
        </Tab>
      </Link>
      <Link  className='no-underline' to="contact" smooth={true} offset={0} duration={100}>
      <Tab setPosition={setPosition}>
        Contact Me
      </Tab>
      </Link>
      <Cursor position={position} />
    </ul>
  );
};

const Tab = ({ children, setPosition }) => {
  const ref = useRef(null);

  return (
    <li
      ref={ref}
      onMouseEnter={() => {
        if (!ref?.current) return;

        const { width } = ref.current.getBoundingClientRect();

        setPosition({
          left: ref.current.offsetLeft,
          width,
          opacity: 1,
        });
      }}
      className="relative z-10 block cursor-pointer px-3 py-1 text-xs uppercase text-white mix-blend-difference md:px-5 md:py-3 md:text-base"
    >
      {children}
    </li>
  );
};

const Cursor = ({ position }) => {
  const { experienceInView, projectsInView, contactInView } = useExperience();
  return (
    <motion.li
      animate={{
        ...position,
      }}
      className={`absolute z-0 h-7 ${experienceInView  || projectsInView || contactInView ? 'bg-white': 'bg-black'} md:h-8 rounded-lg`} // Changed background color to white
    />
  );
};
