import React from 'react';
import HoverDevCards from '../motion/HoverDevCards';
import { AnimatedWords } from './TopPage.tsx';

function Experience() {
    return (
        <div className='min-h-screen container' id='experience'>
            <div className='row'>
                <div className='flex justify-center items-center mb-10'>
                    <h1 className="py-20 text-white text-3xl md:text-2xl lg:text-5xl">
                        My <span className="font-bold">Experience</span>
                    </h1>
                </div>
                <div className='col-12 col-md-12'>
                    <HoverDevCards />
                </div>
            </div>
        </div>
    );
}

export default Experience;
